import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointsService, CustomBreakpointsResponse } from './breakpoints.service';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private breakpointService: BreakpointsService
  ) {}

  public observeChanges$(): Observable<Breakpoints> {
    return this.breakpointObserver.observe(this.breakpointService.getBreakpoints()).pipe(
      map(observeResponse => this.getActiveBreakpointName(observeResponse.breakpoints)),
      distinctUntilChanged()
    );
  }

  public getActiveBreakpointName(breakpoints: CustomBreakpointsResponse): Breakpoints {
    for (const media in breakpoints) {
      if (breakpoints[media]) {
        return this.breakpointService.getBreakpointName(media) ?? Breakpoints.None;
      }
    }

    return Breakpoints.None;
  }
}
