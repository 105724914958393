import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs';
import { ButtonConfig, ButtonConfigBuilder } from '../../../base-components';
import { SimpleYesNoFooterComponent } from './footer/simple-yes-no-footer.component';
import { SimpleYesNoHeaderComponent } from './header/simple-yes-no-header.component';

@Component({
  selector: 'prime-simple-yes-no-modal',
  templateUrl: './simple-yes-no-modal.component.html',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf, SimpleYesNoHeaderComponent, NgTemplateOutlet, SimpleYesNoFooterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleYesNoModalComponent implements OnInit {
  @Input() headerLabel?: string;
  @Input() cancelButtonLabel?: string;
  @Input() confirmButtonLabel?: string;
  @Input() closeOnCancel: boolean;
  @Input() confirmButtonDisabledStreamManager?: Observable<boolean>;
  @Input() justifyPosition: 'start' | 'end' | 'between' = 'end';

  @ContentChild('header', { static: false }) header: TemplateRef<any> | null;
  @ContentChild('content', { static: false }) content: TemplateRef<any> | null;
  @ContentChild('footer', { static: false }) footer: TemplateRef<any> | null;

  public cancelButtonConfig?: ButtonConfig;
  public confirmButtonConfig?: ButtonConfig;

  @Output() onCancel: EventEmitter<void>;
  @Output() onConfirm: EventEmitter<void>;

  constructor() {
    this.header = null;
    this.content = null;
    this.footer = null;

    this.closeOnCancel = true;
    this.onCancel = new EventEmitter<void>();
    this.onConfirm = new EventEmitter<void>();
  }

  ngOnInit(): void {
    this.cancelButtonConfig = new ButtonConfigBuilder(
      this.cancelButtonLabel ?? 'Cancel'
    ).buttonConfig;
    this.confirmButtonConfig = new ButtonConfigBuilder(
      this.confirmButtonLabel ?? 'Yes'
    ).buttonConfig;
  }

  cancelHandler(): void {
    this.onCancel.emit();
  }

  confirmHandler() {
    this.onConfirm.emit();
  }
}
