import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  static getFileSize = (bytes: number, toFixed = 1): string => {
    const kilobytes = bytes * 0.0009765625;
    const megabytes = kilobytes * 0.0009765625;
    const gigabytes = megabytes * 0.0009765625;

    if (gigabytes > 1) {
      return gigabytes.toFixed(toFixed) + ' GB';
    }

    if (megabytes > 1) {
      return megabytes.toFixed(toFixed) + ' MB';
    }

    if (kilobytes > 1) {
      return kilobytes.toFixed(toFixed) + ' KB';
    }

    return bytes.toFixed(toFixed) + ' B';
  };

  static createInputTypeFileElement(): HTMLInputElement {
    const tempElement: HTMLInputElement = document.createElement('input');
    tempElement.setAttribute('type', 'file');

    return tempElement;
  }

  static setAllowedInputTypeFileAttributes(
    tempElement: HTMLInputElement,
    attributes: { [key: string]: string | boolean }
  ): void {
    if (attributes) {
      ['accept', 'multiple', 'capture'].forEach(allowedAttribute => {
        if (attributes[allowedAttribute]) {
          tempElement.setAttribute(allowedAttribute, String(attributes[allowedAttribute]));
        }
      });
    }
  }

  openSelectFileDialog(options: {
    accept: string | boolean;
    multiple: boolean;
    maxFileSize?: number;
  }): Observable<File[]> {
    return new Observable<File[]>((observer: Observer<File[]>): void => {
      const tempElement: HTMLInputElement = AttachmentService.createInputTypeFileElement();
      AttachmentService.setAllowedInputTypeFileAttributes(tempElement, {
        accept: options.accept,
        multiple: options.multiple
      });
      tempElement.style.display = 'none';
      document.body.appendChild(tempElement);
      tempElement.onchange = (event: Event): void => {
        const fileList = (event.target as HTMLInputElement)?.files;
        const files: File[] = fileList ? Array.from(fileList) : [];
        const maxFileSize = options?.maxFileSize;
        if (options && maxFileSize && files.some(file => file.size > maxFileSize)) {
          observer.error('maxFileSize');
          observer.complete();

          return;
        }

        observer.next(files);
        observer.complete();

        document.body.removeChild(tempElement);
      };
      tempElement.click();
    });
  }
}
