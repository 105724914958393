import { inject, Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { CRMOptions } from '@pinnakl/crm/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrmOptionsApiService {
  private readonly crmOptionsEndpoint = `${V3_ENDPOINT}crm_options`;
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);

  async load(): Promise<CRMOptions> {
    try {
      return await firstValueFrom(this.loadCrmOptions());
    } catch (e) {
      this.toast.warning('Unable to load CRM options. ' + getErrorMessage('', e));
      throw e;
    }
  }

  private loadCrmOptions(): Observable<CRMOptions> {
    return this.wsp.get<CRMOptions>({
      endpoint: this.crmOptionsEndpoint
    });
  }
}
