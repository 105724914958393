import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { CRMOptions } from '@pinnakl/crm/domain';
import { withDevtools } from '@pinnakl/shared/signal-store';
import { CrmOptionsApiService } from './crm-options-api.service';

interface CrmOptionsState {
  options: CRMOptions | null;
  optionsLoading: boolean;
  optionsLoaded: boolean;
}

const featureKey = 'crmOptions';

export const CrmOptionsStore = signalStore(
  { providedIn: 'root' },
  withDevtools(featureKey),
  withState<CrmOptionsState>({
    options: null,
    optionsLoading: false,
    optionsLoaded: false
  }),
  withMethods((store, api = inject(CrmOptionsApiService)) => ({
    loadCrmOptions: async (forceFetch = false): Promise<CRMOptions | null> => {
      if (forceFetch || (!store.optionsLoaded() && !store.optionsLoading())) {
        patchState(store, { optionsLoading: true });
        try {
          const options = await api.load();
          patchState(store, { options, optionsLoading: false, optionsLoaded: true });
          return options;
        } catch (e) {
          console.error(e);
          patchState(store, { optionsLoading: false, optionsLoaded: true });
          return null;
        }
      } else {
        return store.options();
      }
    }
  }))
);
