<ng-container *ngIf="header; else simpleHeader">
  <ng-container *ngTemplateOutlet="header"></ng-container>
</ng-container>
<ng-template #simpleHeader>
  <prime-simple-yes-no-header
    (onClose)="cancelHandler()"
    *ngIf="headerLabel"
    [label]="headerLabel"
  ></prime-simple-yes-no-header>
</ng-template>

<ng-container *ngIf="content">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-container *ngIf="footer; else simpleFooter">
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>
<ng-template #simpleFooter>
  <prime-simple-yes-no-footer
    (onCancel)="cancelHandler()"
    (onConfirm)="confirmHandler()"
    [cancelButtonConfig]="cancelButtonConfig"
    [closeOnCancel]="closeOnCancel"
    [confirmButtonConfig]="confirmButtonConfig"
    [confirmButtonDisabledStreamManager]="confirmButtonDisabledStreamManager ?? null"
    [justifyPosition]="justifyPosition"
  ></prime-simple-yes-no-footer>
</ng-template>
