import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';

type DragDropEvent = DragEvent & { originalEvent: { dataTransfer: DataTransfer | null } };

@Directive({ selector: '[pinnaklFileDrop]', standalone: true })
export class FileDropDirective {
  protected element: ElementRef;
  fileOver = false;
  @Input({ required: true }) maxFileSize = 0;
  @Input() disabled = false;
  @Output() fileDropped: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() fileDropError: EventEmitter<'maxFileSize' | 'fileTypes'> = new EventEmitter();

  @HostBinding('class') get classes(): string {
    return this.fileOver && !this.disabled ? '__file-over' : '';
  }

  constructor(element: ElementRef) {
    this.element = element;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragDropEvent): void {
    if (this.disabled) return;

    const transfer: DataTransfer | null = this.getTransfer(event);
    if (!transfer?.files) {
      return;
    }
    const files = Array.from(transfer?.files);
    this.preventAndStop(event);
    this.fileOver = false;
    if (this.maxFileSize && files.some(file => file.size > this.maxFileSize)) {
      this.fileDropError.emit('maxFileSize');
    } else {
      this.fileDropped.emit(files);
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragDropEvent): void {
    if (this.disabled) return;

    const transfer: DataTransfer | null = this.getTransfer(event);
    if (!transfer || !this.haveFiles(transfer.types)) {
      return;
    }

    transfer.dropEffect = 'copy';
    this.preventAndStop(event);
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragDropEvent): void {
    if (this.disabled) return;

    if (this.element) {
      if (event.currentTarget !== this.element.nativeElement) {
        return;
      }
    }

    this.preventAndStop(event);
    this.fileOver = false;
  }

  protected getTransfer(event: DragDropEvent): DataTransfer | null {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  protected preventAndStop(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  protected haveFiles(types: readonly string[]): boolean {
    if (!types) {
      return false;
    }

    if (types.indexOf) {
      return types.indexOf('Files') !== -1;
    } else if (types?.includes) {
      return types.includes('Files');
    } else {
      return false;
    }
  }
}
