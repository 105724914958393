import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LocalSpinnerComponent } from '../local-spinner';

@Component({
  standalone: true,
  selector: 'pinnakl-local-spinner-simple-mode-container',
  templateUrl: 'local-spinner-simple-mode-container.component.html',
  styleUrls: ['local-spinner-simple-mode-container.component.scss'],
  imports: [NgClass, NgStyle, LocalSpinnerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalSpinnerSimpleModeContainerComponent {
  @Input() spinnerClasses = '';
  @Input() position: 'absolute' | 'relative' = 'relative';
  @Input() size = '100px';
  @Input() backgroundColor = '';
  @Input() containerWidth = '100%';
  @Input() containerHeight = '150px';
  @Input() containerNgStyle: Record<string, string> = {};

  get combinedContainerNgStyle(): Record<string, string> {
    return {
      width: this.containerWidth,
      height: this.containerHeight,
      ...this.containerNgStyle
    };
  }
}
