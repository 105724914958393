import { Pipe, PipeTransform } from '@angular/core';

const kilobyte = 1024;
const megabyte = 1048576;

@Pipe({
  name: 'fileSize',
  standalone: true
})
export class FileSizePipe implements PipeTransform {
  transform(size: number): string {
    if (size < kilobyte) {
      return `${size} B`;
    }
    if (size < megabyte) {
      return `${(size / kilobyte).toFixed(0)} KB`;
    }
    return `${(size / megabyte).toFixed(2)} MB`;
  }
}
