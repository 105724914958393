@if (vm$ | async; as vm) {
  <div
    pinnaklFileDrop
    [disabled]="isDisabled"
    [maxFileSize]="maxFileSize"
    [ngClass]="{
      'cursor-pointer': !isDisabled,
      'opacity-50': isDisabled,
      'border-dashed border-round border-bluegray-200': bordered,
      'p-0': isModalView,
      'p-1': isNarrowView && !isModalView,
      'p-2': !isNarrowView && !isModalView
    }"
    class="pinnakl-dropzone w-full flex-row align-items-center justify-content-center"
    (click)="!isDisabled && openSelectFileDialog()"
    (fileDropped)="!vm.layout.isMobile && filesDropped($event)"
    (fileDropError)="handleUploadError($event)"
  >
    @if (vm.layout.isMobile || forceButton || buttonTemplate) {
      @if (buttonTemplate) {
        <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
      } @else {
        <prime-button
          label="Choose files"
          [outlined]="true"
          class="w-full mb-3"
        ></prime-button>
      }
    } @else {
      <div
        [ngClass]="{
          'p-3': isModalView,
          'gap-2': !isNarrowView,
          'gap-1': isNarrowView
        }"
        class="flex flex-column align-items-center justify-content-center"
      >
        <i
          inlineSVG="sprites.svg#cloud-upload"
          class="flex w-1_50rem h-1rem text-bluegray-400"
        ></i>
        <p class="mt-0 text-bluegray-600 text-sm font-normal line-height-1rem">
          Drag & drop to upload file or click to select file
        </p>
      </div>
    }

    @if (attachments.length) {
      <div
        [ngClass]="{
          'upload-attachments': multiple,
          'flex justify-content-center': !multiple
        }"
      >
        @for (attachment of attachments; track attachment.id) {
          <div
            class="flex w-full align-items-center gap-2 px-3 border-round-lg border-1 border-bluegray-200 h-2_50rem"
          >
            <i
              inlineSVG="sprites.svg#document"
              class="flex w-1rem h-1rem"
            ></i>
            <span
              [title]="attachment.filename"
              class="text-ellipsis-line-1 overflow-hidden flex-grow-1"
              >{{ attachment.filename }}</span
            >
            <span class="white-space-nowrap px-2">{{ attachment.fileSize | fileSize }}</span>
            <i
              inlineSVG="sprites.svg#thin-close"
              class="cursor-pointer flex w-2rem h-0_75rem"
              (click)="$event.stopPropagation(); removeAttachmentEvent.emit(attachment.id)"
            ></i>
          </div>
        }

        <!-- TODO: start using when progress is implemented -->
        <!--        @if (vm?.progressMap) {-->
        <!--          @for (attachment of attachments; track attachment.id) {-->
        <!--            @if (vm.progressMap?.get(attachment.id) ?? 0; as progress) {-->
        <!--              <div class="file-tile w-full flex flex-column">-->
        <!--                <div class="flex flex-row justify-content-between">-->
        <!--                  <div class="text-gray-100">-->
        <!--                    <p class="my-0 text-sm text-ellipsis-line-1 overflow-hidden">-->
        <!--                      {{ attachment.filename }}-->
        <!--                    </p>-->
        <!--                  </div>-->
        <!--                  <div class="text-sm white-space-nowrap">-->
        <!--                    <span>{{ vm.progressMap?.get(attachment.id) ?? 0 }}%&nbsp;</span>-->
        <!--                    @if (attachment?.fileSize) {-->
        <!--                      <span class="text-gray-100"-->
        <!--                      >({{ attachment.fileSize * (progress / 100) | fileSize }}&nbsp;from&nbsp;{{-->
        <!--                          attachment.fileSize! | fileSize-->
        <!--                        }})</span-->
        <!--                      >-->
        <!--                    }-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <p-progressBar styleClass="w-full" [value]="progress"></p-progressBar>-->
        <!--              </div>-->
        <!--            }-->
        <!--          }-->
        <!--        }-->
      </div>
    }
  </div>
}
