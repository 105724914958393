import { Injectable } from '@angular/core';

// Refer to /libs/shared-ui/src/lib/styles/_components/_variables.scss
export enum Breakpoints {
  None = 'None',
  XSmall = 'XSmall', // old-phones
  Small = 'Small', // phones
  Medium = 'Medium', // tablets-portrait
  Large = 'Large', //tablets-landscape
  XLarge = 'XLarge', // laptops
  XXLarge = 'XXLarge', // desktops
  XXXLarge = 'XXXLarge', // desktops-large
  XXXLargePlus = 'XXXLargePlus' // bigger desktops-large (1920+)
}

export const enum Viewports {
  XSmallEnd = 421,
  SmallEnd = 600,
  MediumEnd = 992,
  LargeEnd = 1024,
  XLargeEnd = 1400,
  XXLargeEnd = 1600,
  XXXLargeEnd = 1920
}

export type CustomBreakpoints = Map<string, Breakpoints>;
export type CustomBreakpointsResponse = { [media: string]: boolean };
const step = 0.01;
const customBreakpoints: CustomBreakpoints = new Map([
  [`(max-width: ${Viewports.XSmallEnd}px)`, Breakpoints.XSmall],
  [
    `(min-width: ${Viewports.XSmallEnd + step}px) and (max-width: ${Viewports.SmallEnd}px)`,
    Breakpoints.Small
  ],
  [
    `(min-width: ${Viewports.SmallEnd + step}px) and (max-width: ${Viewports.MediumEnd}px)`,
    Breakpoints.Medium
  ],
  [
    `(min-width: ${Viewports.MediumEnd + step}px) and (max-width: ${Viewports.LargeEnd}px)`,
    Breakpoints.Large
  ],
  [
    `(min-width: ${Viewports.LargeEnd + step}px) and (max-width: ${Viewports.XLargeEnd}px)`,
    Breakpoints.XLarge
  ],
  [
    `(min-width: ${Viewports.XLargeEnd + step}px) and (max-width: ${Viewports.XXLargeEnd}px)`,
    Breakpoints.XXLarge
  ],
  [
    `(min-width: ${Viewports.XXLargeEnd + step}px) and (max-width: ${Viewports.XXXLargeEnd}px)`,
    Breakpoints.XXXLarge
  ],
  [`(min-width: ${Viewports.XXXLargeEnd + step}px)`, Breakpoints.XXXLargePlus]
]);

@Injectable({ providedIn: 'root' })
export class BreakpointsService {
  public getBreakpoints(): string[] {
    return [...customBreakpoints.keys()];
  }

  public getBreakpointName(breakpointValue: string): Breakpoints | undefined {
    return customBreakpoints.get(breakpointValue);
  }

  public isXSmall(layout: Breakpoints): boolean {
    return layout === Breakpoints.XSmall;
  }

  public isSmall(layout: Breakpoints): boolean {
    return layout === Breakpoints.Small;
  }

  public isMedium(layout: Breakpoints): boolean {
    return layout === Breakpoints.Medium;
  }

  public isLarge(layout: Breakpoints): boolean {
    return layout === Breakpoints.Large;
  }

  public isXLarge(layout: Breakpoints): boolean {
    return layout === Breakpoints.XLarge;
  }

  public isXXLarge(layout: Breakpoints): boolean {
    return layout === Breakpoints.XXLarge;
  }

  public isXXXLarge(layout: Breakpoints): boolean {
    return layout === Breakpoints.XXXLarge;
  }

  isXlargeAndAbove(layout: Breakpoints): boolean {
    return [Breakpoints.XLarge, Breakpoints.XXLarge, Breakpoints.XXXLarge].includes(layout);
  }

  public isSmallOrXSLayout(layout: Breakpoints): boolean {
    return [Breakpoints.Small, Breakpoints.XSmall].includes(layout);
  }
}
