<div
  [ngClass]="position"
  [ngStyle]="combinedContainerNgStyle"
>
  <pinnakl-local-spinner
    [backgroundColor]="backgroundColor"
    [classes]="spinnerClasses"
    [size]="size"
  ></pinnakl-local-spinner>
</div>
